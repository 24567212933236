import React, { useEffect, useState, useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import { Formik, Form } from 'formik'
import { Box, Container } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import loadMarketoForm from '@helpers/load-marketo-form'
import isUX1Country from '@helpers/ux1-country'
import renderBloks from '@renderBloks'
import useLocalizedStrings from '@hooks/use-localized-strings'
import { H4, Button } from '@system'
import MultiColumnForm from './multi-column-form'
import SingleColumnForm from './single-column-form'
import isInBrowser from '@helpers/is-in-browser'
import theme from '@theme'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import multilinkToUrl from '@helpers/multilink-to-url'
import { get } from 'lodash'
import { useExitIntent } from 'use-exit-intent'
import {
  getCookie,
  getEncryptedCookie,
  formCookieKey,
  saveEncryptedValuesToCookieStore,
} from '@cookies'
import isApacSite from '@helpers/is-apac-site'
import makeApacCountryField from '@helpers/make-apac-country-field'
import trackFormSubmission from '@helpers/track-form-submission'

const useStyles = makeStyles({
  title: {
    color: theme.palette.text.primary,
    marginBottom: '16px',
    textAlign: ({ blok }) =>
      blok.variant === 'twoColumnSuggestionForm' && 'center',
    display: ({ isQuiz }) => (isQuiz ? 'none' : 'block'),
  },
  privacyText: {
    color: theme.palette.text.secondary,
    padding: '10px 0px',
    lineSpacing: '0.1rem',
    '& a': {
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& ul': {
      fontSize: 10,
      listStyleType: 'none',
      paddingLeft: '0px',
      '& li': {
        '&:before': {
          display: 'none',
        },
      },
    },
    display: 'block',
  },
  description: {
    color: ({ blok }) =>
      blok.variant === 'dark'
        ? theme.palette.text.white
        : theme.palette.text.primary,
    textAlign: ({ blok }) => (blok.variant === 'dark' ? 'center' : 'left'),
    marginBottom: '16px',
    display: ({ isQuiz }) => (isQuiz ? 'none' : 'block'),
  },
  heading: {
    color: theme.palette.text.hint,
    marginBottom: '0px',
    display: 'block',
    position: 'relative',
    bottom: '-10px',
    fontWeight: theme.typography.fontWeightBold,
  },
  thanksBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: '48px 0',
  },
  formWrapper: ({ handleNavatticModule, isQuiz }) => ({
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
    width: '100%',
    backgroundColor: handleNavatticModule
      ? theme.palette.common.white
      : theme.palette.background.paper,
    padding: handleNavatticModule ? '16px' : '2rem 0',
    height: isQuiz ? '100%' : null,
    display: isQuiz ? 'flex' : null,
    flexDirection: isQuiz ? 'column' : null,
    justifyContent: isQuiz ? 'center' : null,
  }),
  buttonLink: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    margin: '0 auto',
    padding: '11px 14px',
    fontWeight: 700,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  afterFormSubmission: {
    minHeight: (props) =>
      props.isUngated && !props.isWebinarsModule ? '150px' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  afterSubmitWebinar: {
    display: 'block',
    '&>div': {
      height: '400px',
      minHeight: 'auto',
      paddingTop: '0',
    },
  },
})

let marketoForm = {}

const MarketoFormModule = (props) => {
  const {
    fields,
    marketoId,
    userSubmittedMessage,
    isTwoColumn,
    hasContainer,
    hasDividerLine,
    typUrl,
    variant,
    customAssetToDownload,
    thankYouContent,
    ...otherProps
  } = props.blok

  const {
    assetToDownload: initAssetToDownload,
    hiddenFields: initHiddenFields,
    isUngated,
    isQuiz,
    quizCallback,
    isSubmitButtonVisible,
    submitButtonText,
    isOnDemandButtonVisible,
    isWebinarsModule,
    shouldHideFormSubmissionStatus,
    wistiaRef,
    isModalContent,
    handleNavatticModule,
  } = props

  const context = useContext(PageContext)
  const { locale, isInEditor } = context

  const hasCustomAssetToDownload =
    !initAssetToDownload &&
    !!customAssetToDownload &&
    !!customAssetToDownload.content

  const assetToDownload =
    initAssetToDownload ||
    (hasCustomAssetToDownload && customAssetToDownload.content.assetToDownload)

  // TODO: Should refactor this into its own helper
  const createHiddenFieldsFromResourceDetail = (customAsset) => {
    if (!customAsset || !customAsset.content) return []

    const { title, workflow, companyType, product, format } =
      customAsset.content

    const fieldIds = [
      'zimportLastAsset',
      'assetWorkflow',
      'Firm_Type__c_lead',
      'product',
      'format',
    ]

    return fieldIds.map((marketoId) => {
      const makeField = (value) => ({ marketoId, value })
      // These values are the standard hidden meta data we push
      // into marketo for all gated resource assets
      switch (marketoId) {
        case 'zimportLastAsset':
          return makeField(title)
        case 'assetWorkflow':
          return makeField(workflow)
        case 'Firm_Type__c_lead':
          return makeField(companyType)
        case 'product':
          return makeField(product)
        case 'format':
          return makeField(format)
        default:
          return null
      }
    })
  }

  const hiddenFields =
    initHiddenFields ||
    (hasCustomAssetToDownload &&
      createHiddenFieldsFromResourceDetail(customAssetToDownload))

  const classes = useStyles(props)
  const isUX1CountryField = (field) =>
    !field.isHiddenForUS || !isUX1Country(locale)

  const [formSubmissionStatus, updateFormSubmissionStatus] = useState(false)
  const [checkboxLabel, fetchCheckboxLabel] = useState([])
  const [hasDownloaded, updateHasDownloaded] = useState(false)
  const [initialFormikValues, setInitialFormikValues] = useState({})
  const localizedStrings = useLocalizedStrings()

  const localizeFieldOptions = (options) =>
    options.map(
      (option) =>
        // For fields like "Job Role" whose fieldOptions is an array of strings, just localize the strings
        (typeof option === 'string' &&
          localizedStrings[option.toLowerCase()]) ||
        // For fields that take in a "select" component that itself has a fieldOptions array, recursively localize
        (typeof option === 'object' && {
          ...option,
          fieldOptions: localizeFieldOptions(option.fieldOptions),
        }) ||
        // Else just return original option
        option
    )

  const sortedFields = [
    ...fields.filter((field) => field.component !== 'marketoFormCheckbox'),
    // ...(isApacSite() ? makeApacCountryField() : []),
    ...fields.filter((field) => field.component === 'marketoFormCheckbox'),
  ]
  const fieldsToRender = sortedFields.filter(isUX1CountryField).map((field) => {
    const localizedFieldOptions =
      (field.fieldOptions && localizeFieldOptions(field.fieldOptions)) ||
      field.fieldOptions
    return { ...field, fieldOptions: localizedFieldOptions }
  })
  const isMultiSelect = fieldsToRender.some(
    (field) => field.component === 'marketoFormMultiSelect'
  )
  const blokProps = props.blok
  const styleProps = classes

  const formProps = {
    fieldsToRender,
    locale,
    isMultiSelect,
    isUX1CountryField,
    fetchCheckboxLabel,
    styleProps,
    isSubmitButtonVisible,
    blokProps,
    hasDividerLine,
    isQuiz,
    quizCallback,
    submitButtonText,
    isModalContent,
    ...otherProps,
  }

  const handleClick = () => {
    if (!!isOnDemandButtonVisible || (isUngated && isWebinarsModule)) {
      wistiaRef.current.scrollIntoView()
    }
    if (!!assetToDownload && isInBrowser) {
      updateHasDownloaded(true)
      window.open(assetToDownload)
    }
  }
  const { unsubscribe, registerHandler } = useExitIntent({
    cookie: {
      key: props.modalCookie,
      daysToExpire: parseInt(Number(props.modalCookieExpire)),
    },
  })

  const onSubmitForm = (formValues) => {
    if (isMultiSelect) {
      const formCheckboxValues = []
      Object.keys(formValues).forEach((key) => {
        checkboxLabel.forEach((value) => {
          if (key.indexOf(value) !== -1) {
            formCheckboxValues.push(key)
            delete formValues[key]
          }
        })
      })
      formValues.partnerWorkflowsSupported = formCheckboxValues
    }

    if (hiddenFields && hiddenFields.length > 0) {
      hiddenFields.forEach(
        (eachHiddenField) =>
          (formValues[eachHiddenField.marketoId] = eachHiddenField.value)
      )
    }

    const _accid = getCookie('_ACCID_')
    if (_accid) {
      formValues.gAUserID = _accid
    }

    marketoForm.setValues(formValues)
    window?.qualified('saveFormData', formValues)

    const validated = marketoForm.validate()
    if (validated) marketoForm.submit()

    const savedValues = getCookie(formCookieKey)
    saveEncryptedValuesToCookieStore(formValues, savedValues)

    //this state will only change in case of webinar's wistia video
    !!isWebinarsModule && props.setFormSubmission(true)
    updateFormSubmissionStatus(validated)
  }

  const FixedContainer = ({ children }) =>
    isTwoColumn || isMultiSelect || hasContainer ? (
      <Container fixed>{children}</Container>
    ) : (
      <>{children}</>
    )

  const id = marketoId || 1372
  const submitButtonProps = get(formProps, 'submitButton[0]', {})
  const url = typUrl && multilinkToUrl(typUrl, isInEditor)
  const hasTypUrl = !!url
  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct
  const pageConversionType =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.conversionType

  registerHandler({
    id: 'onUnsubscription',
    handler: () => {},
    context: ['onUnsubscribe'],
  })

  useEffect(() => {
    loadMarketoForm(id, (marketoFormObj) => {
      if (marketoFormObj) {
        marketoForm = marketoFormObj

        marketoFormObj.onSuccess((values, followUpUrl) => {
          const formId = id
          const dataProduct =
            submitButtonProps.dataProduct || pageDataProduct || 'generic'

          if (handleNavatticModule) {
            trackFormSubmission(formId, dataProduct).then(() => {
              handleNavatticModule()
            })

            return false
          }

          const handleTyp = () => {
            unsubscribe()

            if (!!quizCallback) {
              quizCallback()
            }

            if (hasTypUrl) {
              navigate(url, {
                state: {
                  asset: assetToDownload,
                  formId: id,
                  conversionType: pageConversionType,
                },
              })
            }
          }

          trackFormSubmission(formId, dataProduct).then(handleTyp())

          return false
        })
      }
    })
  }, [quizCallback])

  let initialValues = {}

  for (const { fieldId, isHiddenForUS, initialValue } of fields) {
    const fieldValue = fieldId.trim().toLowerCase()
    let value = initialValue || ''
    if (isHiddenForUS && isUX1Country(locale)) value = true

    // Load values from cookie store into form
    const savedValues = getCookie(formCookieKey)
    if (savedValues) {
      const decryptedValues = getEncryptedCookie(savedValues)
      initialValues = {
        ...initialValues,
        ...decryptedValues,
        [fieldValue]: value,
      }
    } else {
      initialValues = { ...initialValues, [fieldValue]: value }
    }
  }

  useEffect(() => {
    setInitialFormikValues(initialValues)
  }, [])

  return (
    <SbEditable content={props.blok}>
      <ThemeProvider theme={theme}>
        <Box
          id="form"
          className={classNames({
            [classes.formWrapper]:
              isTwoColumn ||
              isMultiSelect ||
              isQuiz ||
              hasContainer ||
              handleNavatticModule,
          })}
        >
          {isUngated || formSubmissionStatus ? (
            hasDownloaded && thankYouContent ? (
              renderBloks(thankYouContent)
            ) : (
              <Box
                className={classNames(classes.afterFormSubmission, {
                  [classes.afterSubmitWebinar]: isWebinarsModule,
                })}
              >
                {!isUngated && !!userSubmittedMessage && (
                  <H4
                    className={classes.thanksBox}
                    component="div"
                    id={`success-submit-${marketoId}`}
                  >
                    {userSubmittedMessage}
                  </H4>
                )}

                {!shouldHideFormSubmissionStatus &&
                  (!!assetToDownload || isOnDemandButtonVisible) && (
                    <Button
                      size="large"
                      variant="contained"
                      className={classes.buttonLink}
                      onClick={handleClick}
                    >
                      {assetToDownload ? 'Download' : 'WATCH WEBINAR'}
                    </Button>
                  )}
              </Box>
            )
          ) : (
            <Formik
              initialValues={initialFormikValues}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  onSubmitForm(values)
                  setSubmitting(false)
                }, 400)
              }}
              render={({
                errors,
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                handleSubmit,
                dirty,
              }) => (
                <FixedContainer>
                  <Form>
                    {isTwoColumn ? (
                      <MultiColumnForm
                        isSubmitting={isSubmitting}
                        values={values}
                        formProps={formProps}
                        variant={variant}
                        localizedStrings={localizedStrings}
                      />
                    ) : (
                      <SingleColumnForm
                        isSubmitting={isSubmitting}
                        values={values}
                        formProps={formProps}
                        variant={variant}
                        assetToDownload={assetToDownload}
                        localizedStrings={localizedStrings}
                      />
                    )}
                  </Form>
                </FixedContainer>
              )}
            />
          )}
        </Box>
      </ThemeProvider>
    </SbEditable>
  )
}

export default MarketoFormModule
