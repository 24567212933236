import React, { useContext } from 'react'
import { Button, LinearProgress, Box, Grid } from '@material-ui/core'
import Field from '../field'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import isUX1Country from '@helpers/ux1-country'
import { H3, Text, TextSm, MarkdownHandler } from '@system'
import Divider from '@material-ui/core/Divider'
import get from 'lodash/get'
import '@helpers/from-index'
import classNames from 'classnames'
import PageContext from '@context'
import { monochrome } from '@helpers/palette'
import Icon from '@system/icon'
import useStringTranslation from '@hooks/use-string-translation'
import { privacyTextKey } from '@variables/static-strings'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  submitButton: {
    width: (props) =>
      props.variant === 'twoColumnSuggestionForm' ? '55%' : '90%',
    marginTop: (props) => props.variant !== 'twoColumnSuggestionForm' && '35px',
    margin: (props) => props.variant === 'twoColumnSuggestionForm' && 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& button': {
      borderRadius: '0',
      fontSize: '18px',
      height: '60px',
    },
  },
  marketoSubmitButton: {
    marginTop: '16px',
    width: '100%',
    minHeight: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '2.1',
    '& span': {
      justifyContent: (props) =>
        props.variant === 'twoColumnSuggestionForm' && 'flex-start',
    },
  },
  endIcon: {
    height: '100%',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 8,
      position: 'absolute',
      right: '5px',
    },
  },
  dividerCta: {
    marginLeft: '13px',
  },
  contained: {
    color: (props) =>
      props.color ? monochrome(props.color.color) : theme.palette.common.white,
    backgroundColor: (props) =>
      props.bgColor
        ? monochrome(props.bgColor.color)
        : theme.palette.common.black,
    padding: (props) => props.size === 'medium' && '0.4rem 1.4rem',
    boxShadow: 'none',
    '&:hover': {
      color: (props) =>
        props.color
          ? monochrome(props.color.color)
          : theme.palette.common.white,
      backgroundColor: (props) =>
        props.bgColor
          ? monochrome(props.bgColor.color)
          : theme.palette.common.black,
      boxShadow: 'none',
    },
  },
  suggestionsForm: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  podcastFormInput: {
    '&>div': {
      width: '100% !important',
    },
  },
  podcastFormTextArea: {
    '&>textarea': {
      height: '160px !important',
    },
  },
  submitButtonContainer: {
    marginLeft: (props) =>
      props.variant !== 'twoColumnSuggestionForm' && 'auto',
    margin: (props) => props.variant === 'twoColumnSuggestionForm' && '0 auto',
  },
}))

const MultiColumnForm = ({
  isSubmitting,
  values,
  variant,
  localizedStrings,
  ...props
}) => {
  const styles = props.formProps.styleProps
  const { title, description, marketoId, submitButton } =
    props.formProps.blokProps

  const {
    fieldsToRender,
    isMultiSelect,
    isUX1CountryField,
    fetchCheckboxLabel,
    locale,
    isSubmitButtonVisible,
    hasDividerLine,
    isModalContent,
    isNotMarketableForm,
  } = props.formProps

  const PRIVACY_TEXT = useStringTranslation(privacyTextKey)
  const SafeCheckboxes = (fieldData) => {
    const safeText = useStringTranslation(fieldData.text || '')
    return {
      ...fieldData,
      text: safeText,
    }
  }

  const submitButtonProps = get(props.formProps, 'submitButton[0]', {})
  const classes = useStyles({
    ...submitButtonProps,
    variant,
  })
  let sum = 0
  const allFields = fieldsToRender
    .filter((field) => !hasDividerLine || !field.isLegal)
    .map((field) => {
      const rowHeight =
        field.component === 'marketoFormMultiSelect'
          ? 3
          : field.fieldType === 'textarea'
          ? 2
          : 1
      sum += rowHeight
      return {
        ...field,
        rowHeight,
        sum,
      }
    })

  const legalFields = fieldsToRender.filter((field) => field.isLegal)
  const totalRows = allFields.reduce((agg, field) => agg + field.rowHeight, 0)
  const targetRows = Math.ceil(totalRows / 2)
  const indexBelowTarget = allFields.findIndex(
    (field) => field.sum >= targetRows
  )

  const lowerDiff = targetRows - allFields[indexBelowTarget].sum
  const upperDiff = allFields[indexBelowTarget + 1] - targetRows

  const targetIndex =
    lowerDiff < upperDiff ? indexBelowTarget : indexBelowTarget + 1

  const col1Fields = allFields.slice(0, targetIndex)
  const col2Fields = allFields.slice(targetIndex, allFields.length)

  const submitButtonIcon = submitButtonProps.icon && (
    <Icon>{submitButtonProps.icon}</Icon>
  )
  const legalCheckboxOmitted = !Object.values(values).includes(
    'optinDataCollection'
  )
  const context = useContext(PageContext)
  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct

  return (
    <Box>
      {!!title && !isModalContent && (
        <H3 display="block" className={styles.title}>
          {title}
        </H3>
      )}
      {!!description && (
        <Text display="block" className={styles.description}>
          {description}
        </Text>
      )}
      <Grid
        container
        spacing={3}
        className={classNames({
          [classes.suggestionsForm]: variant === 'twoColumnSuggestionForm',
        })}
      >
        <Grid item sm={6} xs={12}>
          {col1Fields &&
            col1Fields.map((fieldData) => (
              <Box
                key={fieldData._uid}
                className={classNames({
                  [classes.podcastFormInput]:
                    variant === 'twoColumnSuggestionForm',
                })}
              >
                {variant !== 'twoColumnSuggestionForm' && (
                  <Text className={styles.heading}>{fieldData.fieldName}</Text>
                )}
                {(fieldData.fieldId === 'optinDataCollection' ||
                  fieldData.fieldId === 'optinMarketingCommunications') &&
                !isNotMarketableForm ? (
                  renderBlok(SafeCheckboxes(fieldData))
                ) : fieldData.component !== 'marketoFormField' ? (
                  <>
                    {fetchCheckboxLabel(
                      fieldData.fieldOptions
                        ? fieldData.fieldOptions[0].options
                        : fieldData.fieldOptions
                    )}
                    {renderBlok(fieldData)}
                  </>
                ) : (
                  <Field
                    {...fieldData}
                    variant={variant}
                    shouldShowPlaceholder={
                      variant === 'twoColumnSuggestionForm'
                    }
                  />
                )}
              </Box>
            ))}
        </Grid>
        <Grid item sm={6} xs={12}>
          {col2Fields &&
            col2Fields.map((fieldData) => (
              <Box
                key={fieldData._uid}
                className={classNames({
                  [classes.podcastFormTextArea]:
                    variant === 'twoColumnSuggestionForm',
                })}
              >
                {variant !== 'twoColumnSuggestionForm' && (
                  <Text className={styles.heading}>{fieldData.fieldName}</Text>
                )}
                {fieldData.component !== 'marketoFormField' ? (
                  (fieldData.fieldId === 'optinDataCollection' ||
                    fieldData.fieldId === 'optinMarketingCommunications') &&
                  !isNotMarketableForm &&
                  isUX1CountryField(fieldData) &&
                  renderBlok(SafeCheckboxes(fieldData))
                ) : fieldData.component !== 'marketoFormField' ? (
                  <>
                    {fetchCheckboxLabel(
                      fieldData.fieldOptions
                        ? fieldData.fieldOptions[0].options
                        : fieldData.fieldOptions
                    )}
                    {renderBlok(fieldData)}
                  </>
                ) : (
                  <Field
                    {...fieldData}
                    shouldShowPlaceholder={
                      variant === 'twoColumnSuggestionForm'
                    }
                  />
                )}
              </Box>
            ))}
        </Grid>
        {hasDividerLine && (
          <Divider
            className={classes.dividerCta}
            width="94%"
            variant="middle"
          />
        )}
        <Grid item sm={6} xs={12} className={classes.submitButtonContainer}>
          {hasDividerLine &&
            legalFields.map((fieldData) => (
              <Box key={fieldData._uid}>
                {fieldData.component !== 'marketoFormField' &&
                fieldData.isLegal === true &&
                isUX1CountryField(fieldData)
                  ? renderBlok(fieldData)
                  : undefined}
              </Box>
            ))}
          {isSubmitting && <LinearProgress />}
          <Box className={classes.submitButton}>
            <Button
              id={`submit-button-${marketoId}`}
              marketo-id={marketoId}
              variant="contained"
              data-analytics={
                submitButtonProps.dataAnalytics || 'Form Submission'
              }
              data-product={
                submitButtonProps.dataProduct || pageDataProduct || 'generic'
              }
              color={submitButton.buttonColor || 'primary'}
              disabled={
                (isUX1Country(locale)
                  ? false
                  : isMultiSelect ||
                    isSubmitButtonVisible ||
                    legalCheckboxOmitted
                  ? false
                  : !values.optinDataCollection) || isSubmitting
              }
              type="submit"
              classes={{
                root: classes.marketoSubmitButton,
                endIcon: classes.endIcon,
                contained: classes.contained,
              }}
              endIcon={submitButtonIcon}
            >
              {localizedStrings[
                submitButtonProps?.text?.toLowerCase() || 'submit'
              ] ||
                submitButtonProps.text ||
                'Submit'}
            </Button>
            {isUX1Country(locale) && !isNotMarketableForm && (
              <TextSm
                display="block"
                component="div"
                className={styles.privacyText}
              >
                {<MarkdownHandler>{PRIVACY_TEXT}</MarkdownHandler>}
              </TextSm>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MultiColumnForm
